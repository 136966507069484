.Spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinnerCircle {
  animation: spin 2s linear 0s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
