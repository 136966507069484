.ItemActions {
  display: flex;
  flex-direction: row;
  max-height: 21px;
}

.action {
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 0.75rem;
  max-height: 21px;
}

.deleteConfirm {
  display: flex;
  flex-direction: column;
  padding-bottom: 4px;
}

.deleteConfirmText {
  padding: 4px;
  text-align: center;
}

.deleteConfirmButtonArea {
  margin-top: 4px;
  display: flex;
}

.deleteConfirmButtonSpace {
  width: 8px;
  flex-shrink: 0;
}
