.Punchlist {
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 8px;
  row-gap: 8px;
  width: 100%;
}

.customAddItem {
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
  background: transparent !important;
  color: #ffffff !important;
}

.customAddItem:focus-within {
  border: 1px solid rgba(255, 255, 255, 1) !important;
  background: transparent !important;
  color: #ffffff !important;
}

.customPlaceholder {
  color: rgba(255, 255, 255, 0.8) !important;
}

.topAddContainer {
  display: flex;
  flex-direction: row;
}

.closeBtn {
  margin-left: 8px;
  height: 46px;
  width: 46px;
  flex-shrink: 0;
  border: 1px solid #ffffff;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
}

.closeBtn:hover {
  opacity: 1;
}

.error {
  color: #ffffff;
}
