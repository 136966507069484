.AuthForm {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.formActionArea {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.forgotLink {
  flex: 1;
}

.invalidCredentialsAlert {
  background: rgba(255, 0, 34, 0.1);
  border: 1px solid #ff0022;
  padding: 8px 16px;
  display: block;
  max-width: 100%;
  border-radius: 4px;
  color: #ff0022;
  width: 100%;
  margin-bottom: 16px;
}

.masterSucess {
  background: rgba(8, 107, 38, 0.1);
  color: #086b26;
  border: 1px solid #086b26;
  margin-bottom: 0;
}

.formTitle {
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 16px;
  letter-spacing: 1px;
}

.inputMargin {
  height: 8px;
}

.inputContainer {
  margin-bottom: 16px;
}

.inputContainer label {
  width: 100%;
}

.loginForm {
  display: flex;
  flex-direction: column;
}

.accountSignUp.formActionArea {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 24px;
}

.AuthForm input {
  width: 100%;
}

.inputContainer {
  margin-top: 8px !important;
}

.entryContainer {
  margin-top: 8px;
}
