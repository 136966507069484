.iconButton {
  height: calc(var(--size) - 8px);
  width: calc(var(--size) - 8px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
}

.iconButton:hover {
  background: rgba(0, 0, 0, 0.1);
}
