.CommonFooter {
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  margin-top: 96px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 96px;
  line-height: 96px;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  font-size: 0.85rem;
}
