.RecentPunchlists {
  display: grid;
  grid-gap: 12px;
  gap: 12px;
}

@media (min-width: 610px) {
  .RecentPunchlists {
    grid-template-columns: 1fr 1fr;
  }
}

/* @media (min-width: 905px) {
  .RecentPunchlists {
    grid-template-columns: 1fr 1fr 1fr;
  }
} */

@media (min-width: 1200px) {
  .RecentPunchlists {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
