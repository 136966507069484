.SocialLogins {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.orContainer {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bar {
  flex: 1;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
}

.divisorTitle {
  padding: 0px 16px;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.65);
}

.loginOption {
  height: 40px;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  align-items: center;
}

.loginOption:hover,
.loginOption:focus {
  border: 1px solid var(--primary-color-100);
}

.loginOptionLogoContainer {
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
}

.loginOptionLogo {
  height: 18px;
  width: 18px;
}

.socialLabelContainer {
  flex: 1;
  font-size: 0.875rem;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.8);
}
