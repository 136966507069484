.SuperButton {
  background: var(--primary-color-100);
  border: none;
  height: 36px;
  border-radius: 4px;
  color: #ffffff;
  padding: 0px 8px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.icon {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  margin-left: 8px;
  margin-right: 8px;
}

.spinnerContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-color-80);
  border-radius: 4px;
}

.spinning.outline {
  color: var(--primary-color-20);
}

.outline .spinnerContainer {
  background: none;
}

.disabled {
  opacity: 0.6;
  cursor: initial;
}

.notVisible {
  display: none;
}

.compact {
  height: 30px !important;
}

.outline {
  background: none !important;
  border: 1px solid var(--primary-color-100);
  color: var(--primary-color-100);
}

.iconOnly {
  padding: 0px 8px;
}
