.ProjectItem {
  --size: 48px;

  height: var(--size);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
  display: flex;
  padding: 0px 16px;
  cursor: pointer;
  color: initial;
}

.ProjectItem:hover {
  background: rgba(0, 0, 0, 0.02);
}

.iconContainer {
  height: 24px;
  color: var(--primary-color-100);
}

.titleContainer {
  margin-left: 16px;
}

.ownerBadge {
  text-transform: uppercase;
  font-size: 0.75rem;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.1);
  margin-left: 8px;
  padding: 2px 4px;
  color: rgba(0, 0, 0, 0.8);
}

.spacer {
  flex: 1;
}

.leftSide {
  flex: 1;
  display: flex;
  height: var(--size);
  align-items: center;
  display: flex;
  color: initial;
  width: 100%;
}

.rightSide {
  height: 48px;
  align-items: center;
  display: flex;
  color: initial;
}

.progressBarContainer {
  width: 200px;
  margin-right: 24px;
}

.progress {
  margin-right: 16px;
  text-align: center;
  font-size: 0.75rem;
  color: var(--primary-color-100);
}
