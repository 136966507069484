.PageHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.PageHeader h1 {
  margin: 0;
}

.titleArea {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.actionArea {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.summary {
  color: rgba(0, 0, 0, 0.6);
}

.titleContainer {
  display: flex;
  flex-direction: row;
}

.spinner {
  height: 32px;
  width: 32px;
  margin-left: 16px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
}
