.UserPickerFlyout {
  position: relative;
  display: inline-block;
}

.flyoutContainer {
  position: absolute;
  top: calc(100% + 8px);
  animation: open 200ms ease;
  z-index: 99999;
}

.userPicker {
  height: 200px;
  width: 100vw;
  max-width: 300px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.topBar {
  padding: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
}

.searchBox {
  font-size: 0.8rem;
  flex: 1;
}

.closeBtn {
  height: 32px;
  width: 32px;
  border: none;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
  border-radius: 4px;
}

.list {
  flex: 1;
  overflow: auto;
}
