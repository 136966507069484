.formEntry {
  margin-top: 24px;
}

.deleteCaption {
  display: flex;
  align-items: center;
}

.deleteBtnContainer {
  flex-shrink: 0;
  margin-left: 32px;
}

.deleteConfirm {
  display: flex;
  flex-direction: column;
  padding-bottom: 4px;
}

.deleteConfirmText {
  padding: 4px;
  text-align: center;
}

.deleteConfirmButtonArea {
  margin-top: 4px;
  display: flex;
}

.deleteConfirmButtonSpace {
  width: 8px;
  flex-shrink: 0;
}

.deleteContainer {
  margin-top: 32px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 32px;
}
