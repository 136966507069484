.PunchlistArea {
  display: grid;
  flex-direction: column;
}

.titlebar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.title {
  flex: 1;
  font-size: 1rem;
  font-weight: 600;
}

.titleLoader {
  height: 20px;
  width: 200px;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 8px;
  grid-row-gap: 8px;
  margin-top: 8px;
}

.showMoreButton {
  line-height: 32px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  background: var(--primary-color-10);
  border-radius: 4px;
  color: var(--primary-color-100);
  font-weight: 600;
}
