.NewTeam {
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  padding: 48px 16px 0px 16px;
  display: flex;
  flex-direction: column;
}

.creationForm {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}

.formInput {
  margin-top: 8px;
}

.formEntryCaption {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.formEntry {
  margin-top: 24px;
}

.insertedUsers {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}

.actionArea {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 32px;
  text-align: right;
}
