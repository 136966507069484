.Projects {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 48px 16px 0px 16px;
  display: flex;
  flex-direction: column;
}

.addBtn {
  margin-left: 8px;
  background: var(--primary-color-100);
  border: none;
  height: 36px;
  border-radius: 4px;
  color: #ffffff;
  padding: 0px 16px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  line-height: 36px;
}

.projectsList {
  margin-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.messageContainer {
  margin-bottom: 24px;
}

.messageSuccess {
  padding: 16px 24px;
  border-radius: 4px;
  background: rgba(0, 255, 0, 0.2);
  color: rgba(0, 0, 0, 0.6);
}
