.TeamItem {
  height: 96px;
  background: #ffffff;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;

  display: flex;
  flex-direction: row;
}

.TeamItem:hover {
  height: 96px;
  background: #ffffff;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--primary-color-100);
  cursor: pointer;
}

.avatarArea {
  height: 96px;
  width: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.descArea {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.teamAvatar {
  height: 64px;
  width: 64px;
  border-radius: 4px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color-100);
  color: #ffffff;
}

.teamName {
  font-weight: 600;
}

.users {
  display: grid;
  grid-column-gap: 4px;
  column-gap: 4px;
  grid-auto-columns: 16px;
  grid-auto-flow: column;
}

.avatar {
  height: 16px;
  width: 16px;
  background-size: cover;
  border-radius: 8px;
  margin-right: 4px;
}

.more {
  height: 16px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 2px;
  color: rgba(0, 0, 0, 0.5);
}

.actionArea {
  --size: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
}
