/* Fonts */
@import url("https://rsms.me/inter/inter.css");
html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212529;
  text-align: left;
  background-color: #fafafa;
}

button,
input,
select {
  font-family: inherit;
  font-size: inherit;
  transition: all 300ms ease;
  transition-property: box-shadow background opacity;
}

.focusLike {
  transition: all 300ms ease;
  transition-property: box-shadow background opacity;
}

button {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  text-align: inherit;
  box-sizing: border-box;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

input[type="text"],
input[type="password"],
input[type="email"] {
  font-family: "Inter";
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: none;
  display: block;
  margin-top: 8px;
  outline: none;
  padding: 0px 12px;
  height: 36px;
  line-height: 36px;
  width: 280px;
  border-radius: 4px;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
select:focus {
  border: 1px solid var(--primary-color-100);
}

select {
  font: inherit;
  padding: 4px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  cursor: pointer;
}

input.invalid {
  border: 1px solid #ff0022;
}

input:disabled {
  opacity: 0.6;
}

::-webkit-input-placeholder {
  font-style: italic;
}

::-moz-placeholder {
  font-style: italic;
}

::-ms-input-placeholder {
  font-style: italic;
}

input:focus,
a:focus,
button:focus,
select:focus,
.focusLike:focus {
  box-shadow: 0px 0px 0px 3px var(--primary-color-20);
  outline: none;
}

.using-mouse a:focus,
.using-mouse button:focus {
  box-shadow: none;
}

a {
  border-radius: 4px;
  color: var(--primary-color-100);
  text-decoration: none;
  background-color: transparent;
}

h1 {
  font-size: 1.5rem;
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: 600;
}

label {
  font-weight: 600;
}

label * {
  font-weight: initial;
}

.linkLikeBtn {
  cursor: pointer;
  color: var(--primary-color-80);
  font-weight: 600;
}

select {
  background: transparent;
  font-weight: 600;
}
