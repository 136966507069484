.PunchlistItem {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  animation: added 300ms ease;
  background: #ffffff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
}

.tickContainer {
  padding: 12px;
}

.detailsContainer {
  flex: 1;
  padding: 12px 12px 12px 0px;
  display: flex;
  flex-direction: row;
}

.title {
  font-size: 1rem;
  line-height: 20px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  padding: 0;
  border-radius: 2px;
  margin: 0;
}

.titleContainer {
  display: flex;
  flex-direction: row;
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  padding-left: 12px;
}

.author {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 4px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.spacer {
  width: 4px;
}

.bigspacer {
  width: 16px;
}

.punched {
  text-decoration: line-through var(--primary-color-100) solid;
  color: rgba(0, 0, 0, 0.6);
  cursor: initial;
}

.editable {
  cursor: text !important;
}

.comments {
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 4px;
  row-gap: 4px;
  width: 100%;
}

.showMoreButton {
  line-height: 32px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  background: var(--primary-color-10);
  border-radius: 4px;
  color: var(--primary-color-100);
  font-weight: 600;
}

@keyframes added {
  0% {
    transform: translateX(-48px);
    opacity: 0;
  }
}

.authorRight {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.detailsGrid {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 0px;
  column-gap: 0px;
  grid-row-gap: 8px;
  row-gap: 8px;
}

.compactGrid {
  grid-template-columns: 1fr;
  grid-column-gap: 0px;
  column-gap: 0px;
  grid-row-gap: 8px;
  row-gap: 8px;
}

.assigneeButton {
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: inherit;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  cursor: pointer;
  font-size: 0.7rem;
}

.assigneeButtonNoPaddingLeft {
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: inherit;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  padding-left: 1px;
  cursor: pointer;
  font-size: 0.7rem;
}

.assigneeButtonNoPadding {
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: inherit;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.7rem;
  min-width: 20px;
  justify-content: center;
}

.generalContainer {
  display: flex;
  flex-direction: row;
}

.commentContainer {
  padding: 6px 12px;
  background-color: #f5f5f5;
  border-top: 1px solid #dcdcdc;
  display: flex;
  flex-direction: row;
}

.commentContainer.expanded {
  padding: 12px;
  padding-top: 3px;
}

.commentsRoot {
  flex: 1;
}

.commentIconContainer {
  flex-shrink: 0;
  width: 20px;
  margin-right: 12px;
}

.commentIcon {
  width: 20px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
}

.expanded .commentIconContainer {
  padding-top: 3px;
}

.moreCommentsSection {
  flex-shrink: 0;
  margin-left: 8px;
}

.moreCommentsButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
}

.moreCommentsButtonExpanded {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  padding-top: 3px;
}

.commentGrid {
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 16px;
  row-gap: 16px;
  width: 100%;
  margin-top: 16px;
}
