.InsertingUserDetails {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  flex-direction: row;
}

.appended {
  background: rgba(0, 0, 0, 0.04) !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-top: none !important;
}

.noRadiusBottom {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.noRadiusTop {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.noBorderBottom {
  border-bottom: none !important;
}

.noBorderTop {
  border-top: none !important;
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.details {
  flex: 1;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name {
  font-size: 0.85rem;
  margin: 0;
  font-weight: 600;
}

.username {
  font-size: 0.7rem;
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loadingContainer {
  height: 36px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
