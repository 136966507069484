.formInput {
  margin-top: 8px;
}

.formEntryCaption {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.formEntry {
  margin-top: 24px;
}

.insertedUsers {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}
