.FloatingMenu {
  position: relative;
}

.menu {
  background: #ffffff;
  position: absolute;
  top: 100%;
  border-radius: 4px;
  padding: 8px 0px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  z-index: 999999;
}

.menuOuter {
  background: transparent;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999998;
}

.FloatingMenu.fixOnLeft .menu {
  left: 0px;
}

.FloatingMenu.fixOnRight .menu {
  right: 0px;
}

.menu ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.menu ul li {
  padding: 0 48px 0px 8px;
  list-style-type: none;
  margin: 0;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.menu ul li:hover {
  background: var(--primary-color-10);
}

.menu ul li .iconContainer {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FloatingMenu.open .menu {
  display: block;
}

.FloatingMenu.closed .menu {
  display: none;
}

.itemFlyout {
  display: block;
}
