.loadingItem {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  animation: added 300ms ease;
  background: #ffffff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
  padding: 12px;
}

.skeletonContainer {
  height: 44px;
  width: 400px;
}
