.ProjectList {
  display: flex;
  flex-direction: column;
}

.noProjects {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 64px;
}

.noProjectsIcon {
  opacity: 0.4;
}

.caption {
  font-size: 1.25rem;
  margin-top: 24px;
  margin-bottom: 8px;
}
