.TopMenu {
  height: 72px;
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.05);
  background: #ffffff;
}

.TopMenu .container {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 16px;
}

.logo {
  height: 32px;
  cursor: pointer;
}

.filler {
  flex: 1;
}

.linksArea {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.currentUser {
  height: 64px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 32px;
}

.currentUser img {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  cursor: pointer;
}

.currentUserLink {
  cursor: pointer;
}
