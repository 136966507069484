.Dashboard {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 48px 16px 0px 16px;
  display: flex;
  flex-direction: column;
}

.grid {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 32px;
  grid-row-gap: 32px;
}

.title {
  flex: 1;
  padding-top: 32px;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.helpBage {
  height: 32px;
  display: flex;
  width: 32px;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 4px;
}

.titleLeft {
  flex: 1;
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
  flex-direction: row;
}

.inputContainer {
  width: 262px;
}

.recentContainer {
  margin-top: 16px;
}
