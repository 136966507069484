.Tick {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ticked {
  background: var(--primary-color-100);
  border: none;
  color: #ffffff;
  cursor: initial;
}
