.TeamsList {
  display: grid;
  grid-gap: 12px;
  gap: 12px;
  margin-top: 16px;
}

.ProjectList {
  display: flex;
  flex-direction: column;
}

@media (min-width: 610px) {
  .TeamsList {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 905px) {
  .TeamsList {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1200px) {
  .TeamsList {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.noProjects {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 64px;
}

.noProjectsIcon {
  opacity: 0.4;
}

.caption {
  font-size: 1.25rem;
  margin-top: 24px;
  margin-bottom: 8px;
}
