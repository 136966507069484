.Flyout {
  position: relative;
  display: inline-block;
}

.flyoutContainer {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  animation: open 200ms ease;
}

.flyoutTriangle {
  height: 0px;
  width: 0px;
  left: 50%;
  border: 4px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.8);
}

.triangleContainer {
  width: 100%;
  bottom: 0;
  height: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flyoutContent {
  background: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  font-size: 0.75rem;
  padding: 4px 8px;
  border-radius: 4px;
  bottom: 8px;
  left: 50%;
  white-space: nowrap;
  line-height: 1.6rem;
  font-weight: 400;
}

@keyframes open {
  0% {
    transform: translate(-50%, 8px) scale(0.5);
    opacity: 0;
  }

  100% {
    transform: translate(-50%, 0px) scale(1);
    opacity: 1;
  }
}
