@import url(https://rsms.me/inter/inter.css);
/* Fonts */
html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212529;
  text-align: left;
  background-color: #fafafa;
}

button,
input,
select {
  font-family: inherit;
  font-size: inherit;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-transition-property: box-shadow background opacity;
  transition-property: box-shadow background opacity;
}

.focusLike {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-transition-property: box-shadow background opacity;
  transition-property: box-shadow background opacity;
}

button {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  text-align: inherit;
  box-sizing: border-box;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

input[type="text"],
input[type="password"],
input[type="email"] {
  font-family: "Inter";
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: none;
  display: block;
  margin-top: 8px;
  outline: none;
  padding: 0px 12px;
  height: 36px;
  line-height: 36px;
  width: 280px;
  border-radius: 4px;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
select:focus {
  border: 1px solid var(--primary-color-100);
}

select {
  font: inherit;
  padding: 4px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  cursor: pointer;
}

input.invalid {
  border: 1px solid #ff0022;
}

input:disabled {
  opacity: 0.6;
}

::-webkit-input-placeholder {
  font-style: italic;
}

::-moz-placeholder {
  font-style: italic;
}

::-ms-input-placeholder {
  font-style: italic;
}

input:focus,
a:focus,
button:focus,
select:focus,
.focusLike:focus {
  box-shadow: 0px 0px 0px 3px var(--primary-color-20);
  outline: none;
}

.using-mouse a:focus,
.using-mouse button:focus {
  box-shadow: none;
}

a {
  border-radius: 4px;
  color: var(--primary-color-100);
  text-decoration: none;
  background-color: transparent;
}

h1 {
  font-size: 1.5rem;
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: 600;
}

label {
  font-weight: 600;
}

label * {
  font-weight: normal;
  font-weight: initial;
}

.linkLikeBtn {
  cursor: pointer;
  color: var(--primary-color-80);
  font-weight: 600;
}

select {
  background: transparent;
  font-weight: 600;
}

:root {
  /* Primary Color */
  --primary-color-100: rgba(0, 90, 255, 1);
  --primary-color-90: rgba(0, 90, 255, 0.9);
  --primary-color-80: rgba(0, 90, 255, 0.8);
  --primary-color-70: rgba(0, 90, 255, 0.7);
  --primary-color-60: rgba(0, 90, 255, 0.6);
  --primary-color-50: rgba(0, 90, 255, 0.5);
  --primary-color-40: rgba(0, 90, 255, 0.4);
  --primary-color-30: rgba(0, 90, 255, 0.3);
  --primary-color-20: rgba(0, 90, 255, 0.2);
  --primary-color-10: rgba(0, 90, 255, 0.1);
  --primary-color-0: rgba(0, 90, 255, 0);
}


.FormWall_FormWall__16fvi {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 32px 24px;
}

.FormWall_content__YTdX5 {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  width: 100%;
  align-items: center;
}

.FormWall_logoContainer__3uc77 {
  margin-bottom: 24px;
  font-size: 24px;
  text-align: center;
  font-weight: 600;

  font-family: "Inter";
}

.FormWall_form__34VD7 {
  padding: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04);
  width: 100%;
  max-width: 328px;
  background: #ffffff;
}

@media (min-height: 600px) {
  .FormWall_FormWall__16fvi {
    padding: 96px 24px;
  }
}
.FormWall_formActionArea__369Iu {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 8px;
}

.FormWall_logo__13hLX {
  height: 32px;
}

.SuperButton_SuperButton__SF1Sz {
  background: var(--primary-color-100);
  border: none;
  height: 36px;
  border-radius: 4px;
  color: #ffffff;
  padding: 0px 8px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.SuperButton_icon__2KmX5 {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SuperButton_label__30sYz {
  margin-left: 8px;
  margin-right: 8px;
}

.SuperButton_spinnerContainer__xnOCV {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-color-80);
  border-radius: 4px;
}

.SuperButton_spinning__3Hgv6.SuperButton_outline__2BaOx {
  color: var(--primary-color-20);
}

.SuperButton_outline__2BaOx .SuperButton_spinnerContainer__xnOCV {
  background: none;
}

.SuperButton_disabled__3gxqx {
  opacity: 0.6;
  cursor: auto;
  cursor: initial;
}

.SuperButton_notVisible__Iz4HF {
  display: none;
}

.SuperButton_compact__3D7u2 {
  height: 30px !important;
}

.SuperButton_outline__2BaOx {
  background: none !important;
  border: 1px solid var(--primary-color-100);
  color: var(--primary-color-100);
}

.SuperButton_iconOnly__11yMI {
  padding: 0px 8px;
}

.Spinner_Spinner__3_m3H {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Spinner_spinnerCircle__G-HEf {
  -webkit-animation: Spinner_spin__2NPaS 2s linear 0s infinite;
          animation: Spinner_spin__2NPaS 2s linear 0s infinite;
}

@-webkit-keyframes Spinner_spin__2NPaS {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Spinner_spin__2NPaS {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.AuthForm_AuthForm__17xU1 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.AuthForm_formActionArea__V94KC {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.AuthForm_forgotLink__2iKKA {
  flex: 1 1;
}

.AuthForm_invalidCredentialsAlert__2ijhr {
  background: rgba(255, 0, 34, 0.1);
  border: 1px solid #ff0022;
  padding: 8px 16px;
  display: block;
  max-width: 100%;
  border-radius: 4px;
  color: #ff0022;
  width: 100%;
  margin-bottom: 16px;
}

.AuthForm_masterSucess__Yr254 {
  background: rgba(8, 107, 38, 0.1);
  color: #086b26;
  border: 1px solid #086b26;
  margin-bottom: 0;
}

.AuthForm_formTitle__5M3Lz {
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 16px;
  letter-spacing: 1px;
}

.AuthForm_inputMargin__2Kx6Y {
  height: 8px;
}

.AuthForm_inputContainer__18bqL {
  margin-bottom: 16px;
}

.AuthForm_inputContainer__18bqL label {
  width: 100%;
}

.AuthForm_loginForm__24fK8 {
  display: flex;
  flex-direction: column;
}

.AuthForm_accountSignUp__3PYg-.AuthForm_formActionArea__V94KC {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 24px;
}

.AuthForm_AuthForm__17xU1 input {
  width: 100%;
}

.AuthForm_inputContainer__18bqL {
  margin-top: 8px !important;
}

.AuthForm_entryContainer__9vmpQ {
  margin-top: 8px;
}

.TextInput_TextInput__1EoDm,
.TextInput_TextInput__1EoDm input {
  width: 100%;
  margin: 0;
  flex: 1 1;
  border-radius: 4px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-transition-property: box-shadow background opacity;
  transition-property: box-shadow background opacity;
  border: none;
}

.TextInput_inputBorder__2MFLj {
  padding: 0px 4px;
}

.TextInput_TextInput__1EoDm input {
  padding: 0px 8px;
}

.TextInput_TextInput__1EoDm input:focus {
  box-shadow: none;
  border: none;
}

.TextInput_TextInput__1EoDm:focus-within .TextInput_inputBorder__2MFLj {
  box-shadow: 0px 0px 0px 3px var(--primary-color-20);
  border-color: var(--primary-color-100);
  color: var(--primary-color-100);
}

.TextInput_TextInput__1EoDm:focus-within .TextInput_icon__3GCaK {
  border-color: var(--primary-color-100);
  color: var(--primary-color-100);
}

.TextInput_TextInput__1EoDm.TextInput_invalid__3qCXE .TextInput_inputBorder__2MFLj {
  border-color: #ff0022 !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.TextInput_errorContainer__3mbj4 {
  background: rgba(255, 0, 34, 0.1);
  border: 1px solid #ff0022;
  padding: 8px 16px;
  display: block;
  max-width: 100%;
  border-radius: 4px;
  color: #ff0022;
  width: 100%;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.TextInput_inputContainer__2Yocg {
  display: flex;
  flex-direction: row;
}

.TextInput_icon__3GCaK {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: text;
  color: rgba(0, 0, 0, 0.65);
  z-index: 10;

  -webkit-transition: all 300ms ease;

  transition: all 300ms ease;
  -webkit-transition-property: box-shadow background opacity;
  transition-property: box-shadow background opacity;
}

.TextInput_spinnerContainer__3gCnk {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: text;
  z-index: 10;
}

.TextInput_TextInput__1EoDm.TextInput_invalid__3qCXE .TextInput_icon__3GCaK {
  color: #ff0022 !important;
}

.TextInput_inputBorder__2MFLj {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex: 1 1;

  -webkit-transition: all 300ms ease;

  transition: all 300ms ease;
  -webkit-transition-property: box-shadow background opacity;
  transition-property: box-shadow background opacity;
}

.TextInput_noRadiusOnBottom__Fx1Tg {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.SocialLogins_SocialLogins__1mTnn {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.SocialLogins_orContainer__1LLO1 {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.SocialLogins_bar__3TQnW {
  flex: 1 1;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
}

.SocialLogins_divisorTitle__1Btf2 {
  padding: 0px 16px;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.65);
}

.SocialLogins_loginOption__14kZr {
  height: 40px;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  align-items: center;
}

.SocialLogins_loginOption__14kZr:hover,
.SocialLogins_loginOption__14kZr:focus {
  border: 1px solid var(--primary-color-100);
}

.SocialLogins_loginOptionLogoContainer__2mDRN {
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
}

.SocialLogins_loginOptionLogo__2rr_2 {
  height: 18px;
  width: 18px;
}

.SocialLogins_socialLabelContainer__1mRG2 {
  flex: 1 1;
  font-size: 0.875rem;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.8);
}

.Home_Home__1XOv- {
  background-color: #fff;
  color: #636b6f;
  font-family: "Inter", sans-serif;
  font-weight: 200;
  height: 100vh;
  margin: 0;
}

.Home_fullHeight__31amB {
  height: 100vh;
}

.Home_flexCenter__3ykas {
  align-items: center;
  display: flex;
  justify-content: center;
}

.Home_positionRef__25AXY {
  position: relative;
}

.Home_topRight__3mEWj {
  position: absolute;
  right: 10px;
  top: 18px;
}

.Home_content__3ZGXp {
  text-align: center;
}

.Home_title__2Cb1B {
  font-size: 84px;
}

.Home_links__174I6 > a,
.Home_links__174I6 > button {
  color: #636b6f;
  padding: 0 25px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.1rem;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.Home_mbmd__3T1CK {
  margin-bottom: 30px;
}

.Home_environmentDesc__2lnFT {
  position: absolute;
  right: 18px;
  bottom: 18px;
}

.Home_logo__3xAiD {
  height: 96px;
}

.Home_spinnerContainer__3y_C1 {
  height: 24px;
  width: 24px;
}

.Home_spinner__1E-fN {
  -webkit-animation: Home_spin__3IGFZ 2s linear 0s infinite;
          animation: Home_spin__3IGFZ 2s linear 0s infinite;
  height: 24px;
  width: 24px;
}

@-webkit-keyframes Home_spin__3IGFZ {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Home_spin__3IGFZ {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.commonStyles_loadingOverlay__2DhDX {
  z-index: 99999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.privateRoute_loadingOverlay__1Yjft {
  z-index: 99999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TopMenu_TopMenu__38s1w {
  height: 72px;
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.05);
  background: #ffffff;
}

.TopMenu_TopMenu__38s1w .TopMenu_container__1jA42 {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 16px;
}

.TopMenu_logo__1CJef {
  height: 32px;
  cursor: pointer;
}

.TopMenu_filler__3rkFd {
  flex: 1 1;
}

.TopMenu_linksArea__hZnCx {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.TopMenu_currentUser__28Tsq {
  height: 64px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 32px;
}

.TopMenu_currentUser__28Tsq img {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  cursor: pointer;
}

.TopMenu_currentUserLink__1ME4S {
  cursor: pointer;
}

.FloatingMenu_FloatingMenu__3FwnP {
  position: relative;
}

.FloatingMenu_menu__9ZXvo {
  background: #ffffff;
  position: absolute;
  top: 100%;
  border-radius: 4px;
  padding: 8px 0px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  z-index: 999999;
}

.FloatingMenu_menuOuter__1Am0T {
  background: transparent;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999998;
}

.FloatingMenu_FloatingMenu__3FwnP.FloatingMenu_fixOnLeft__3cZbD .FloatingMenu_menu__9ZXvo {
  left: 0px;
}

.FloatingMenu_FloatingMenu__3FwnP.FloatingMenu_fixOnRight__13x3u .FloatingMenu_menu__9ZXvo {
  right: 0px;
}

.FloatingMenu_menu__9ZXvo ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.FloatingMenu_menu__9ZXvo ul li {
  padding: 0 48px 0px 8px;
  list-style-type: none;
  margin: 0;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.FloatingMenu_menu__9ZXvo ul li:hover {
  background: var(--primary-color-10);
}

.FloatingMenu_menu__9ZXvo ul li .FloatingMenu_iconContainer__2NiGK {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FloatingMenu_FloatingMenu__3FwnP.FloatingMenu_open__2gaVq .FloatingMenu_menu__9ZXvo {
  display: block;
}

.FloatingMenu_FloatingMenu__3FwnP.FloatingMenu_closed__1tx8e .FloatingMenu_menu__9ZXvo {
  display: none;
}

.FloatingMenu_itemFlyout__2ZdOt {
  display: block;
}

.Flyout_Flyout__25VNB {
  position: relative;
  display: inline-block;
}

.Flyout_flyoutContainer__YApaH {
  position: absolute;
  bottom: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-animation: Flyout_open__E23Tc 200ms ease;
          animation: Flyout_open__E23Tc 200ms ease;
}

.Flyout_flyoutTriangle__smQnR {
  height: 0px;
  width: 0px;
  left: 50%;
  border: 4px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.8);
}

.Flyout_triangleContainer__3agM- {
  width: 100%;
  bottom: 0;
  height: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Flyout_flyoutContent__2zwyO {
  background: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  font-size: 0.75rem;
  padding: 4px 8px;
  border-radius: 4px;
  bottom: 8px;
  left: 50%;
  white-space: nowrap;
  line-height: 1.6rem;
  font-weight: 400;
}

@-webkit-keyframes Flyout_open__E23Tc {
  0% {
    -webkit-transform: translate(-50%, 8px) scale(0.5);
            transform: translate(-50%, 8px) scale(0.5);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(-50%, 0px) scale(1);
            transform: translate(-50%, 0px) scale(1);
    opacity: 1;
  }
}

@keyframes Flyout_open__E23Tc {
  0% {
    -webkit-transform: translate(-50%, 8px) scale(0.5);
            transform: translate(-50%, 8px) scale(0.5);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(-50%, 0px) scale(1);
            transform: translate(-50%, 0px) scale(1);
    opacity: 1;
  }
}

.TopMenuLink_TopMenuLink__2Eek- {
  display: flex;
  border-radius: 0;
  flex-direction: column;
  color: inherit;
  margin-left: 16px;
}

.TopMenuLink_content__3Xbmb {
  flex: 1 1;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  color: rgba(0, 0, 0, 0.65);
}

.TopMenuLink_indicator__A3_IJ {
  height: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.TopMenuLink_indicatorTop__2UeSt {
  height: 4px;
  flex-shrink: 0;
}

.TopMenuLink_active__2qCrs .TopMenuLink_content__3Xbmb {
  color: var(--primary-color-100);
}

.TopMenuLink_active__2qCrs .TopMenuLink_indicator__A3_IJ {
  background: var(--primary-color-100);
}

.UserAvatar_UserAvatar__2MQTK {
  background-size: cover;
}

.AppBootstrap_AppBootstrap__3Xi0Z .AppBootstrap_page-container__3Mp8w {
  margin: 0 auto;
  width: 100%;
  max-width: 948px;
}

.Dashboard_Dashboard__1cxbv {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 48px 16px 0px 16px;
  display: flex;
  flex-direction: column;
}

.Dashboard_grid__2meUt {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 32px;
  row-gap: 32px;
  grid-row-gap: 32px;
}

.Dashboard_title__1H9oI {
  flex: 1 1;
  padding-top: 32px;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.Dashboard_helpBage__29uPx {
  height: 32px;
  display: flex;
  width: 32px;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 4px;
}

.Dashboard_titleLeft__KNms2 {
  flex: 1 1;
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
  flex-direction: row;
}

.Dashboard_inputContainer__1zzsi {
  width: 262px;
}

.Dashboard_recentContainer__K6MnD {
  margin-top: 16px;
}

.PageHeader_PageHeader__1kuOT {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.PageHeader_PageHeader__1kuOT h1 {
  margin: 0;
}

.PageHeader_titleArea__2rteZ {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.PageHeader_actionArea__2kIFl {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.PageHeader_summary__2jZV6 {
  color: rgba(0, 0, 0, 0.6);
}

.PageHeader_titleContainer__10iyy {
  display: flex;
  flex-direction: row;
}

.PageHeader_spinner__hW7S0 {
  height: 32px;
  width: 32px;
  margin-left: 16px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.PunchlistArea_PunchlistArea__3SWak {
  display: grid;
  flex-direction: column;
}

.PunchlistArea_titlebar__UXO4n {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.PunchlistArea_title__154ti {
  flex: 1 1;
  font-size: 1rem;
  font-weight: 600;
}

.PunchlistArea_titleLoader__3PTfR {
  height: 20px;
  width: 200px;
}

.PunchlistArea_list__2mS4Z {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
  row-gap: 8px;
  grid-row-gap: 8px;
  margin-top: 8px;
}

.PunchlistArea_showMoreButton__3WrdO {
  line-height: 32px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  background: var(--primary-color-10);
  border-radius: 4px;
  color: var(--primary-color-100);
  font-weight: 600;
}

.PunchlistItem_PunchlistItem__1msL5 {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  -webkit-animation: PunchlistItem_added__1kCKW 300ms ease;
          animation: PunchlistItem_added__1kCKW 300ms ease;
  background: #ffffff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
}

.PunchlistItem_tickContainer__3ue8L {
  padding: 12px;
}

.PunchlistItem_detailsContainer__33yeZ {
  flex: 1 1;
  padding: 12px 12px 12px 0px;
  display: flex;
  flex-direction: row;
}

.PunchlistItem_title__1lRcd {
  font-size: 1rem;
  line-height: 20px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  padding: 0;
  border-radius: 2px;
  margin: 0;
}

.PunchlistItem_titleContainer__2bwG6 {
  display: flex;
  flex-direction: row;
}

.PunchlistItem_actionsContainer__10FHB {
  display: flex;
  flex-direction: row;
  padding-left: 12px;
}

.PunchlistItem_author__1qe6Y {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 4px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.PunchlistItem_spacer__1UbqY {
  width: 4px;
}

.PunchlistItem_bigspacer__3GfDh {
  width: 16px;
}

.PunchlistItem_punched__1AK7H {
  -webkit-text-decoration: line-through var(--primary-color-100) solid;
          text-decoration: line-through var(--primary-color-100) solid;
  color: rgba(0, 0, 0, 0.6);
  cursor: auto;
  cursor: initial;
}

.PunchlistItem_editable__8jQoL {
  cursor: text !important;
}

.PunchlistItem_comments__2u871 {
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 4px;
  grid-row-gap: 4px;
  row-gap: 4px;
  width: 100%;
}

.PunchlistItem_showMoreButton__26zpB {
  line-height: 32px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  background: var(--primary-color-10);
  border-radius: 4px;
  color: var(--primary-color-100);
  font-weight: 600;
}

@-webkit-keyframes PunchlistItem_added__1kCKW {
  0% {
    -webkit-transform: translateX(-48px);
            transform: translateX(-48px);
    opacity: 0;
  }
}

@keyframes PunchlistItem_added__1kCKW {
  0% {
    -webkit-transform: translateX(-48px);
            transform: translateX(-48px);
    opacity: 0;
  }
}

.PunchlistItem_authorRight__1Bblt {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.PunchlistItem_detailsGrid__336Gs {
  flex: 1 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 0px;
  grid-column-gap: 0px;
  -webkit-column-gap: 0px;
     -moz-column-gap: 0px;
          column-gap: 0px;
  grid-row-gap: 8px;
  grid-row-gap: 8px;
  row-gap: 8px;
}

.PunchlistItem_compactGrid__2dlWi {
  grid-template-columns: 1fr;
  grid-column-gap: 0px;
  grid-column-gap: 0px;
  -webkit-column-gap: 0px;
     -moz-column-gap: 0px;
          column-gap: 0px;
  grid-row-gap: 8px;
  grid-row-gap: 8px;
  row-gap: 8px;
}

.PunchlistItem_assigneeButton__1BDyM {
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: inherit;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  cursor: pointer;
  font-size: 0.7rem;
}

.PunchlistItem_assigneeButtonNoPaddingLeft__19Z1c {
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: inherit;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  padding-left: 1px;
  cursor: pointer;
  font-size: 0.7rem;
}

.PunchlistItem_assigneeButtonNoPadding__1p0Aj {
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: inherit;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.7rem;
  min-width: 20px;
  justify-content: center;
}

.PunchlistItem_generalContainer__2of1_ {
  display: flex;
  flex-direction: row;
}

.PunchlistItem_commentContainer__EC2t9 {
  padding: 6px 12px;
  background-color: #f5f5f5;
  border-top: 1px solid #dcdcdc;
  display: flex;
  flex-direction: row;
}

.PunchlistItem_commentContainer__EC2t9.PunchlistItem_expanded__3PGwi {
  padding: 12px;
  padding-top: 3px;
}

.PunchlistItem_commentsRoot__3W21z {
  flex: 1 1;
}

.PunchlistItem_commentIconContainer__3HO8a {
  flex-shrink: 0;
  width: 20px;
  margin-right: 12px;
}

.PunchlistItem_commentIcon__22Yjb {
  width: 20px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
}

.PunchlistItem_expanded__3PGwi .PunchlistItem_commentIconContainer__3HO8a {
  padding-top: 3px;
}

.PunchlistItem_moreCommentsSection__3zbjx {
  flex-shrink: 0;
  margin-left: 8px;
}

.PunchlistItem_moreCommentsButton__3vS85 {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
}

.PunchlistItem_moreCommentsButtonExpanded__3libA {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  padding-top: 3px;
}

.PunchlistItem_commentGrid__3vYN5 {
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 16px;
  grid-row-gap: 16px;
  row-gap: 16px;
  width: 100%;
  margin-top: 16px;
}

.Tick_Tick__2UtCQ {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Tick_ticked__2owkO {
  background: var(--primary-color-100);
  border: none;
  color: #ffffff;
  cursor: auto;
  cursor: initial;
}

.ItemActions_ItemActions__1lscu {
  display: flex;
  flex-direction: row;
  max-height: 21px;
}

.ItemActions_action__r4ago {
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 0.75rem;
  max-height: 21px;
}

.ItemActions_deleteConfirm__23sEb {
  display: flex;
  flex-direction: column;
  padding-bottom: 4px;
}

.ItemActions_deleteConfirmText__2Xjwj {
  padding: 4px;
  text-align: center;
}

.ItemActions_deleteConfirmButtonArea__3bzwg {
  margin-top: 4px;
  display: flex;
}

.ItemActions_deleteConfirmButtonSpace__3bfkb {
  width: 8px;
  flex-shrink: 0;
}

.ItemComment_ItemComment__3ZRls {
  /* padding: 12px; */
  /* background: rgba(0, 0, 0, 0.06); */
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  -webkit-animation: ItemComment_added__FeXOx 300ms ease;
          animation: ItemComment_added__FeXOx 300ms ease;
}

.ItemComment_titleArea__2e5Xh {
  display: flex;
  flex-direction: row;
}

.ItemComment_profileInfo__8LG2N {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  justify-content: center;
  flex: 1 1;
}

.ItemComment_compact__32B3t .ItemComment_profileInfo__8LG2N {
  flex: initial;
  flex-shrink: 0;
  justify-content: initial;
}

.ItemComment_avatarContainer__3Wftc {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 24px;
  min-width: 20px;
}

.ItemComment_profileName__3O0Rw {
  font-size: 0.75rem;
  line-height: 12px;
  font-weight: 600;
}

.ItemComment_compact__32B3t .ItemComment_profileName__3O0Rw {
  line-height: 1.5rem;
}

.ItemComment_profileUsername__1vuDi {
  font-size: 0.6rem;
  line-height: 12px;
  margin-top: 2px;
  color: rgba(0, 0, 0, 0.65);
}

.ItemComment_content__S43Ns {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.875rem;
  padding-left: 40px;

  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;

  word-break: break-word;
}

.ItemComment_contentFirst__1IcbB {
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.75rem;
  padding: 0 4px;
  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;

  word-break: break-word;
}

.ItemComment_collapsed__1r-hs {
  max-height: 3rem;
}

.ItemComment_contentFirst__1IcbB.ItemComment_collapsed__1r-hs {
  max-height: 1.5rem;
}

.ItemComment_showMoreButton__-RH9R {
  margin-left: 40px;
  margin-top: 8px;
  cursor: pointer;
  color: var(--primary-color-80);
  font-weight: 600;
}

.ItemComment_showMoreButtonCompact__1Axy1 {
  margin-left: 8px;
  margin-right: 12px;
  cursor: pointer;
  color: var(--primary-color-80);
  font-weight: 600;
  flex-shrink: 0;
  line-height: 1.5rem;
  font-size: 0.75rem;
}

.ItemComment_showLessButtonCompact__vb7rx {
  display: block;
  cursor: pointer;
  color: var(--primary-color-80);
  font-weight: 600;
  flex-shrink: 0;
  line-height: 1.5rem;
  font-size: 0.75rem;
}

@-webkit-keyframes ItemComment_added__FeXOx {
  0% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    opacity: 0;
  }
}

@keyframes ItemComment_added__FeXOx {
  0% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    opacity: 0;
  }
}

.ItemComment_optionsBtn__MYyjo {
  cursor: pointer;
}

.ItemComment_compact__32B3t {
  padding: 0px;
}

.AddCommentBox_AddCommentBox__2dZra {
  display: flex;
  flex-direction: row;
  padding: 8px;
  padding-left: 16px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  -webkit-transition: box-shadow 300ms ease;
  transition: box-shadow 300ms ease;
}

.AddCommentBox_contentBox__3YRkx {
  color: rgba(0, 0, 0, 1);
  word-break: break-word;
}

.AddCommentBox_placeholder__3eCPP {
  color: rgba(0, 0, 0, 0.6);
}

.AddCommentBox_contentBox__3YRkx,
.AddCommentBox_placeholder__3eCPP {
  font-size: 0.875rem;
  cursor: text;
  outline: none;
  line-height: 30px;
  flex: 1 1;
}

.AddCommentBox_AddCommentBox__2dZra:focus-within {
  border: 1px solid var(--primary-color-100);
  padding: 7px;
  padding-left: 16px;
  box-shadow: 0px 0px 0px 3px var(--primary-color-20);
}

.AddCommentBox_boxFocused__136X2 {
  padding: 7px;
  padding-left: 16px;
}

.AddCommentBox_boxBtn__24Q0n {
  text-align: right;
  margin-left: 16px;

  display: flex;
  flex-direction: row-reverse;
}

.CommentsBar_CommentsBar__3ePCb {
  height: 32px;
  width: 100%;
  display: flex;
}

.CommentsBar_titleLeft__2uW6m {
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 32px;
  flex: 1 1;
}

.CommentsBar_titleRight__3vTX3 {
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 32px;
  display: flex;
  flex-direction: row;
}

.CommentsBar_btnContainer__Zznsq {
  margin-left: 12px;
}

.CommentsBar_btnContainer__Zznsq button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.CommentsBar_CommentsBar__3ePCb select {
  font: inherit;
  padding: 4px;

  cursor: pointer;
  background: transparent;
  border: none;
}

.UserPickerFlyout_UserPickerFlyout__3CuOa {
  position: relative;
  display: inline-block;
}

.UserPickerFlyout_flyoutContainer__a-LJ9 {
  position: absolute;
  top: calc(100% + 8px);
  -webkit-animation: UserPickerFlyout_open__QTodf 200ms ease;
          animation: UserPickerFlyout_open__QTodf 200ms ease;
  z-index: 99999;
}

.UserPickerFlyout_userPicker__2Juo4 {
  height: 200px;
  width: 100vw;
  max-width: 300px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.UserPickerFlyout_topBar__1RKcG {
  padding: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
}

.UserPickerFlyout_searchBox__2uqBp {
  font-size: 0.8rem;
  flex: 1 1;
}

.UserPickerFlyout_closeBtn__3q4NT {
  height: 32px;
  width: 32px;
  border: none;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
  border-radius: 4px;
}

.UserPickerFlyout_list__3xlYl {
  flex: 1 1;
  overflow: auto;
}

.UserItem_user__3LDGG {
  height: 48px;
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.UserItem_user__3LDGG:hover {
  background: rgba(0, 0, 0, 0.1);
}

.UserItem_userDetails__2dRgV {
  flex-direction: column;
  justify-content: center;
  display: flex;
  margin-left: 8px;
}

.UserItem_name__22IHP {
  margin-top: 0;
  color: #000000;
  line-height: 12px;
  font-weight: 600;
}

.UserItem_username__1vAig {
  margin-top: 2px;
  line-height: 12px;
}

.SkeletonPunchlistItem_loadingItem__2DcNb {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  -webkit-animation: SkeletonPunchlistItem_added__1hi8m 300ms ease;
          animation: SkeletonPunchlistItem_added__1hi8m 300ms ease;
  background: #ffffff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
  padding: 12px;
}

.SkeletonPunchlistItem_skeletonContainer__UvbNW {
  height: 44px;
  width: 400px;
}

.RecentItem_RecentItem__1tkZT {
  height: 64px;
  background: #ffffff;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;

  display: flex;
  flex-direction: row;
}

.RecentItem_RecentItem__1tkZT:hover {
  height: 64px;
  background: #ffffff;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--primary-color-100);
  cursor: pointer;
}

.RecentItem_avatarArea__etPpG {
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RecentItem_descArea__CbbHo {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.RecentItem_teamAvatar___z_Fk {
  height: 40px;
  width: 40px;
  border-radius: 24px;
  background-size: cover;
  background-color: var(--primary-color-100);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RecentItem_teamName__3IAVZ {
  font-weight: 600;
}

.RecentItem_users__1wB-Q {
  display: grid;
  grid-column-gap: 4px;
  grid-column-gap: 4px;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
  grid-auto-columns: 16px;
  grid-auto-flow: column;
}

.RecentItem_avatar__AIcjX {
  height: 16px;
  width: 16px;
  background-size: cover;
  border-radius: 8px;
  margin-right: 4px;
}

.RecentPunchlists_RecentPunchlists__1Ef5_ {
  display: grid;
  grid-gap: 12px;
  grid-gap: 12px;
  gap: 12px;
}

@media (min-width: 610px) {
  .RecentPunchlists_RecentPunchlists__1Ef5_ {
    grid-template-columns: 1fr 1fr;
  }
}

/* @media (min-width: 905px) {
  .RecentPunchlists {
    grid-template-columns: 1fr 1fr 1fr;
  }
} */

@media (min-width: 1200px) {
  .RecentPunchlists_RecentPunchlists__1Ef5_ {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.NewItem_RecentItem__2oaIv {
  height: 64px;
  width: 100%;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;

  display: flex;
  flex-direction: row;
  color: rgba(0, 0, 0, 0.6);
}

.NewItem_RecentItem__2oaIv:hover {
  height: 64px;
  background: #ffffff;
  width: 100%;
  border-radius: 4px;
  border: 2px solid var(--primary-color-100);
  cursor: pointer;
}

.NewItem_avatarArea__12Fm3 {
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NewItem_descArea__13Y0M {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.NewItem_teamAvatar__2lWC3 {
  height: 40px;
  width: 40px;
  border-radius: 24px;
  background-size: cover;
  color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(0, 0, 0, 0.4);
}

.NewItem_teamName__bZhNi {
  font-weight: 600;
}

.NewItem_users__2GRNS {
  display: grid;
  grid-column-gap: 4px;
  grid-column-gap: 4px;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
  grid-auto-columns: 16px;
  grid-auto-flow: column;
}

.NewItem_avatar__soZ1A {
  height: 16px;
  width: 16px;
  background-size: cover;
  border-radius: 8px;
  margin-right: 4px;
}

.Teams_Teams__1bksy {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 48px 16px 0px 16px;
  display: flex;
  flex-direction: column;
}

.Teams_addBtn__30-2e {
  margin-left: 8px;
  background: var(--primary-color-100);
  border: none;
  height: 36px;
  border-radius: 4px;
  color: #ffffff;
  padding: 0px 16px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  line-height: 36px;
}

.Teams_projectsList__ShicX {
  margin-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.Teams_messageContainer__WQ_bQ {
  margin-bottom: 24px;
}

.Teams_messageSuccess__1Jl7Z {
  padding: 16px 24px;
  border-radius: 4px;
  background: rgba(0, 255, 0, 0.2);
  color: rgba(0, 0, 0, 0.6);
}

.TeamsList_TeamsList__2-7vq {
  display: grid;
  grid-gap: 12px;
  grid-gap: 12px;
  gap: 12px;
  margin-top: 16px;
}

.TeamsList_ProjectList__1lH6R {
  display: flex;
  flex-direction: column;
}

@media (min-width: 610px) {
  .TeamsList_TeamsList__2-7vq {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 905px) {
  .TeamsList_TeamsList__2-7vq {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1200px) {
  .TeamsList_TeamsList__2-7vq {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.TeamsList_noProjects__2MrWd {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 64px;
}

.TeamsList_noProjectsIcon__1yzk_ {
  opacity: 0.4;
}

.TeamsList_caption__MDM4y {
  font-size: 1.25rem;
  margin-top: 24px;
  margin-bottom: 8px;
}

.TeamItem_TeamItem__1yp5j {
  height: 96px;
  background: #ffffff;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;

  display: flex;
  flex-direction: row;
}

.TeamItem_TeamItem__1yp5j:hover {
  height: 96px;
  background: #ffffff;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--primary-color-100);
  cursor: pointer;
}

.TeamItem_avatarArea__266vL {
  height: 96px;
  width: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TeamItem_descArea__27KFI {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TeamItem_teamAvatar__3hcUv {
  height: 64px;
  width: 64px;
  border-radius: 4px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color-100);
  color: #ffffff;
}

.TeamItem_teamName__1ng-x {
  font-weight: 600;
}

.TeamItem_users__2v87W {
  display: grid;
  grid-column-gap: 4px;
  grid-column-gap: 4px;
  -webkit-column-gap: 4px;
     -moz-column-gap: 4px;
          column-gap: 4px;
  grid-auto-columns: 16px;
  grid-auto-flow: column;
}

.TeamItem_avatar__34Wno {
  height: 16px;
  width: 16px;
  background-size: cover;
  border-radius: 8px;
  margin-right: 4px;
}

.TeamItem_more__36s86 {
  height: 16px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 2px;
  color: rgba(0, 0, 0, 0.5);
}

.TeamItem_actionArea__3R9_Z {
  --size: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
}

.FlatIconButton_iconButton__13uAQ {
  height: calc(var(--size) - 8px);
  width: calc(var(--size) - 8px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
}

.FlatIconButton_iconButton__13uAQ:hover {
  background: rgba(0, 0, 0, 0.1);
}

.NewTeam_NewTeam__3kY8A {
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  padding: 48px 16px 0px 16px;
  display: flex;
  flex-direction: column;
}

.NewTeam_creationForm__U2mgd {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}

.NewTeam_formInput__30QcP {
  margin-top: 8px;
}

.NewTeam_formEntryCaption__21Y4w {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.NewTeam_formEntry__wYMNE {
  margin-top: 24px;
}

.NewTeam_insertedUsers__3pp43 {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}

.NewTeam_actionArea__3Q1Z4 {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 32px;
  text-align: right;
}

.InsertingUserDetails_InsertingUserDetails__rW-pt {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  flex-direction: row;
}

.InsertingUserDetails_appended__2I3ol {
  background: rgba(0, 0, 0, 0.04) !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-top: none !important;
}

.InsertingUserDetails_noRadiusBottom__1SWce {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.InsertingUserDetails_noRadiusTop__3KgwW {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.InsertingUserDetails_noBorderBottom__1C2lF {
  border-bottom: none !important;
}

.InsertingUserDetails_noBorderTop__3te7O {
  border-top: none !important;
}

.InsertingUserDetails_avatarContainer__3IIC2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.InsertingUserDetails_details__2V8Sv {
  flex: 1 1;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.InsertingUserDetails_name__st-IZ {
  font-size: 0.85rem;
  margin: 0;
  font-weight: 600;
}

.InsertingUserDetails_username__2x3Kf {
  font-size: 0.7rem;
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
}

.InsertingUserDetails_buttonContainer__1RYw7 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.InsertingUserDetails_loadingContainer__2sLI2 {
  height: 36px;
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProjectUserList_formInput__1L8NB {
  margin-top: 8px;
}

.ProjectUserList_formEntryCaption__3MGzs {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.ProjectUserList_formEntry__2mU9W {
  margin-top: 24px;
}

.ProjectUserList_insertedUsers__BplXE {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}

.CommonFooter_CommonFooter__3quhP {
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  margin-top: 96px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 96px;
  line-height: 96px;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  font-size: 0.85rem;
}

.Punchlist_Punchlist__2D63X {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 48px 16px 0px 16px;
  display: flex;
  flex-direction: column;
}

.Punchlist_container__22QGE {
  margin-top: 16px;
}

.Punchlist_topBar__2d5U1 {
  background: var(--primary-color-100);
  position: absolute;
  z-index: -1;
  height: 194px;
  width: 100%;
}

.Punchlist_search__1V63q * {
  border-color: rgba(255, 255, 255, 0.4) !important;
  color: rgba(255, 255, 255, 0.4);
}

.Punchlist_search__1V63q:focus-within * {
  border-color: #ffffff !important;
  color: #ffffff;
}

.Punchlist_search__1V63q input {
  color: #ffffff;
}

.Punchlist_search__1V63q {
  margin-right: 8px;
  height: 30px;
}

.Punchlist_search__1V63q ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.Punchlist_search__1V63q ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.Punchlist_search__1V63q :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.Punchlist_search__1V63q ::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.Punchlist_search__1V63q ::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.Punchlist_exportBtn__2u1q2 {
  margin-right: 8px;
}

.Punchlist_switcherContainer___QyfN {
  height: 48px;
  background: var(--primary-color-100);
}

.Punchlist_switcher__336hr {
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
}

.Punchlist_manageBtn__3tJP- {
  margin-left: 16px;
}

.Punchlist_Punchlist__2KjAp {
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 8px;
  grid-row-gap: 8px;
  row-gap: 8px;
  width: 100%;
}

.Punchlist_customAddItem__2tyun {
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
  background: transparent !important;
  color: #ffffff !important;
}

.Punchlist_customAddItem__2tyun:focus-within {
  border: 1px solid rgba(255, 255, 255, 1) !important;
  background: transparent !important;
  color: #ffffff !important;
}

.Punchlist_customPlaceholder__1_rfi {
  color: rgba(255, 255, 255, 0.8) !important;
}

.Punchlist_topAddContainer__3gGBr {
  display: flex;
  flex-direction: row;
}

.Punchlist_closeBtn__L8aMM {
  margin-left: 8px;
  height: 46px;
  width: 46px;
  flex-shrink: 0;
  border: 1px solid #ffffff;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
}

.Punchlist_closeBtn__L8aMM:hover {
  opacity: 1;
}

.Punchlist_error__2Mod0 {
  color: #ffffff;
}

.AddingItem_AddingItem__2K_Dj {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
}

.AddingItem_AddingItem__2K_Dj:focus-within {
  border: 1px solid var(--primary-color-100);
}

.AddingItem_tickContainer__3r24M {
  padding: 12px;
}

.AddingItem_detailsContainer__2vzc_ {
  flex: 1 1;
  padding: 12px 12px 12px 0px;
  display: flex;
  flex-direction: column;
}

.AddingItem_placeholder__1aoNT {
  font-size: 1rem;
  line-height: 20px;
  font-weight: 600;
  cursor: text;
  font-family: inherit;
  padding: 0;
  resize: vertical;
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0.65);
}

.AddingItem_title__tLhbq {
  font-size: 1rem;
  line-height: 20px;
  font-weight: 600;
  cursor: text;
  font-family: inherit;
  padding: 0;
  resize: vertical;
  border: none;
  outline: none;
}

.AddingItem_author__3w98m {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 4px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.AddingItem_spacer__MM2Ba {
  width: 4px;
}

.AddingItem_bigspacer__1jewQ {
  width: 16px;
}

.AddingItem_punched__2wQk7 {
  -webkit-text-decoration: line-through var(--primary-color-100) solid;
          text-decoration: line-through var(--primary-color-100) solid;
  color: rgba(0, 0, 0, 0.6);
}

.Projects_Projects__3269n {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 48px 16px 0px 16px;
  display: flex;
  flex-direction: column;
}

.Projects_addBtn__1NDWG {
  margin-left: 8px;
  background: var(--primary-color-100);
  border: none;
  height: 36px;
  border-radius: 4px;
  color: #ffffff;
  padding: 0px 16px;
  font-weight: 600;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  line-height: 36px;
}

.Projects_projectsList__3DJda {
  margin-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.Projects_messageContainer__KznkI {
  margin-bottom: 24px;
}

.Projects_messageSuccess__2D9mu {
  padding: 16px 24px;
  border-radius: 4px;
  background: rgba(0, 255, 0, 0.2);
  color: rgba(0, 0, 0, 0.6);
}

.ProjectsList_ProjectList__26CrD {
  display: flex;
  flex-direction: column;
}

.ProjectsList_noProjects__d7KQN {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 64px;
}

.ProjectsList_noProjectsIcon__3D53c {
  opacity: 0.4;
}

.ProjectsList_caption__mGU4a {
  font-size: 1.25rem;
  margin-top: 24px;
  margin-bottom: 8px;
}

.ProjectsListItem_ProjectItem__3qBk9 {
  --size: 48px;

  height: var(--size);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
  display: flex;
  padding: 0px 16px;
  cursor: pointer;
  color: #000;
  color: initial;
}

.ProjectsListItem_ProjectItem__3qBk9:hover {
  background: rgba(0, 0, 0, 0.02);
}

.ProjectsListItem_iconContainer__1FfPZ {
  height: 24px;
  color: var(--primary-color-100);
}

.ProjectsListItem_titleContainer__VcD6u {
  margin-left: 16px;
}

.ProjectsListItem_ownerBadge__3oklV {
  text-transform: uppercase;
  font-size: 0.75rem;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.1);
  margin-left: 8px;
  padding: 2px 4px;
  color: rgba(0, 0, 0, 0.8);
}

.ProjectsListItem_spacer__1p-W- {
  flex: 1 1;
}

.ProjectsListItem_leftSide__3Em9g {
  flex: 1 1;
  display: flex;
  height: var(--size);
  align-items: center;
  display: flex;
  color: #000;
  color: initial;
  width: 100%;
}

.ProjectsListItem_rightSide__wYTOt {
  height: 48px;
  align-items: center;
  display: flex;
  color: #000;
  color: initial;
}

.ProjectsListItem_progressBarContainer__VCWj8 {
  width: 200px;
  margin-right: 24px;
}

.ProjectsListItem_progress__2LKNY {
  margin-right: 16px;
  text-align: center;
  font-size: 0.75rem;
  color: var(--primary-color-100);
}

.ProgressBar_ProgressBar__1yPrx {
  height: 8px;
  border-radius: 4px;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  position: relative;
}

.ProgressBar_container__1Hvhk {
  display: flex;
  flex-direction: row;
  position: absolute;
  min-width: 8px;
}

.ProgressBar_track__2aSQ0 {
  height: 8px;
  background: var(--primary-color-100);
  flex: 1 1;
}

.ProgressBar_leftCap__fOKLh {
  height: 8px;
  background: var(--primary-color-100);
  width: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.ProgressBar_rightCap__2nv2z {
  height: 8px;
  background: var(--primary-color-100);
  width: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  left: 4px;
}

.NewProject_NewProject__2PViO {
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  padding: 48px 16px 0px 16px;
  display: flex;
  flex-direction: column;
}

.NewProject_creationForm__3O8dQ {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}

.NewProject_formInput__3Na_x {
  margin-top: 8px;
}

.NewProject_formEntryCaption__1CXu1 {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.NewProject_formEntry__2Xd5m {
  margin-top: 24px;
}

.NewProject_insertedUsers__19vtM {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}

.NewProject_actionArea__pKXKT {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 32px;
  text-align: right;
}

.Manage_Manage__1FH-b {
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  padding: 48px 16px 0px 16px;
  display: flex;
  flex-direction: column;
}

.Manage_creationForm__1BlpR {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}

.Manage_formInput__3r-US {
  margin-top: 8px;
}

.Manage_formEntryCaption__XwaBS {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.Manage_formEntry__3qYph {
  margin-top: 24px;
}

.Manage_insertedUsers__2cdzQ {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}

.Manage_actionArea__3NO4w {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 32px;
  text-align: right;
}

.DangerArea_formEntry__3tqIg {
  margin-top: 24px;
}

.DangerArea_deleteCaption__17ZRu {
  display: flex;
  align-items: center;
}

.DangerArea_deleteBtnContainer__1N5Vw {
  flex-shrink: 0;
  margin-left: 32px;
}

.DangerArea_deleteConfirm__2J4E7 {
  display: flex;
  flex-direction: column;
  padding-bottom: 4px;
}

.DangerArea_deleteConfirmText__cBSnX {
  padding: 4px;
  text-align: center;
}

.DangerArea_deleteConfirmButtonArea__3q1nk {
  margin-top: 4px;
  display: flex;
}

.DangerArea_deleteConfirmButtonSpace__1vFI6 {
  width: 8px;
  flex-shrink: 0;
}

.DangerArea_deleteContainer__3pfJp {
  margin-top: 32px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 32px;
}

.Manage_Manage__1Su0s {
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  padding: 48px 16px 0px 16px;
  display: flex;
  flex-direction: column;
}

.Manage_creationForm__3WXmf {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}

.Manage_formInput__3R91S {
  margin-top: 8px;
}

.Manage_formEntryCaption__1AVlL {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.Manage_formEntry__15gH6 {
  margin-top: 24px;
}

.Manage_insertedUsers__2g6OT {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}

.Manage_actionArea__RTtda {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 32px;
  text-align: right;
}

