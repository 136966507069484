.AddCommentBox {
  display: flex;
  flex-direction: row;
  padding: 8px;
  padding-left: 16px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  transition: box-shadow 300ms ease;
}

.contentBox {
  color: rgba(0, 0, 0, 1);
  word-break: break-word;
}

.placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.contentBox,
.placeholder {
  font-size: 0.875rem;
  cursor: text;
  outline: none;
  line-height: 30px;
  flex: 1;
}

.AddCommentBox:focus-within {
  border: 1px solid var(--primary-color-100);
  padding: 7px;
  padding-left: 16px;
  box-shadow: 0px 0px 0px 3px var(--primary-color-20);
}

.boxFocused {
  padding: 7px;
  padding-left: 16px;
}

.boxBtn {
  text-align: right;
  margin-left: 16px;

  display: flex;
  flex-direction: row-reverse;
}
