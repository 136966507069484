:root {
  /* Primary Color */
  --primary-color-100: rgba(0, 90, 255, 1);
  --primary-color-90: rgba(0, 90, 255, 0.9);
  --primary-color-80: rgba(0, 90, 255, 0.8);
  --primary-color-70: rgba(0, 90, 255, 0.7);
  --primary-color-60: rgba(0, 90, 255, 0.6);
  --primary-color-50: rgba(0, 90, 255, 0.5);
  --primary-color-40: rgba(0, 90, 255, 0.4);
  --primary-color-30: rgba(0, 90, 255, 0.3);
  --primary-color-20: rgba(0, 90, 255, 0.2);
  --primary-color-10: rgba(0, 90, 255, 0.1);
  --primary-color-0: rgba(0, 90, 255, 0);
}
