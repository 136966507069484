.RecentItem {
  height: 64px;
  width: 100%;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;

  display: flex;
  flex-direction: row;
  color: rgba(0, 0, 0, 0.6);
}

.RecentItem:hover {
  height: 64px;
  background: #ffffff;
  width: 100%;
  border-radius: 4px;
  border: 2px solid var(--primary-color-100);
  cursor: pointer;
}

.avatarArea {
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.descArea {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.teamAvatar {
  height: 40px;
  width: 40px;
  border-radius: 24px;
  background-size: cover;
  color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(0, 0, 0, 0.4);
}

.teamName {
  font-weight: 600;
}

.users {
  display: grid;
  grid-column-gap: 4px;
  column-gap: 4px;
  grid-auto-columns: 16px;
  grid-auto-flow: column;
}

.avatar {
  height: 16px;
  width: 16px;
  background-size: cover;
  border-radius: 8px;
  margin-right: 4px;
}
