.AddingItem {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
}

.AddingItem:focus-within {
  border: 1px solid var(--primary-color-100);
}

.tickContainer {
  padding: 12px;
}

.detailsContainer {
  flex: 1;
  padding: 12px 12px 12px 0px;
  display: flex;
  flex-direction: column;
}

.placeholder {
  font-size: 1rem;
  line-height: 20px;
  font-weight: 600;
  cursor: text;
  font-family: inherit;
  padding: 0;
  resize: vertical;
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0.65);
}

.title {
  font-size: 1rem;
  line-height: 20px;
  font-weight: 600;
  cursor: text;
  font-family: inherit;
  padding: 0;
  resize: vertical;
  border: none;
  outline: none;
}

.author {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 4px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.spacer {
  width: 4px;
}

.bigspacer {
  width: 16px;
}

.punched {
  text-decoration: line-through var(--primary-color-100) solid;
  color: rgba(0, 0, 0, 0.6);
}
