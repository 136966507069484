.user {
  height: 48px;
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.user:hover {
  background: rgba(0, 0, 0, 0.1);
}

.userDetails {
  flex-direction: column;
  justify-content: center;
  display: flex;
  margin-left: 8px;
}

.name {
  margin-top: 0;
  color: #000000;
  line-height: 12px;
  font-weight: 600;
}

.username {
  margin-top: 2px;
  line-height: 12px;
}
