.ItemComment {
  /* padding: 12px; */
  /* background: rgba(0, 0, 0, 0.06); */
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  animation: added 300ms ease;
}

.titleArea {
  display: flex;
  flex-direction: row;
}

.profileInfo {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  justify-content: center;
  flex: 1;
}

.compact .profileInfo {
  flex: initial;
  flex-shrink: 0;
  justify-content: initial;
}

.avatarContainer {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 24px;
  min-width: 20px;
}

.profileName {
  font-size: 0.75rem;
  line-height: 12px;
  font-weight: 600;
}

.compact .profileName {
  line-height: 1.5rem;
}

.profileUsername {
  font-size: 0.6rem;
  line-height: 12px;
  margin-top: 2px;
  color: rgba(0, 0, 0, 0.65);
}

.content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.875rem;
  padding-left: 40px;

  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;

  word-break: break-word;
}

.contentFirst {
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.75rem;
  padding: 0 4px;
  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;

  word-break: break-word;
}

.collapsed {
  max-height: 3rem;
}

.contentFirst.collapsed {
  max-height: 1.5rem;
}

.showMoreButton {
  margin-left: 40px;
  margin-top: 8px;
  cursor: pointer;
  color: var(--primary-color-80);
  font-weight: 600;
}

.showMoreButtonCompact {
  margin-left: 8px;
  margin-right: 12px;
  cursor: pointer;
  color: var(--primary-color-80);
  font-weight: 600;
  flex-shrink: 0;
  line-height: 1.5rem;
  font-size: 0.75rem;
}

.showLessButtonCompact {
  display: block;
  cursor: pointer;
  color: var(--primary-color-80);
  font-weight: 600;
  flex-shrink: 0;
  line-height: 1.5rem;
  font-size: 0.75rem;
}

@keyframes added {
  0% {
    transform: translateY(-12px);
    opacity: 0;
  }
}

.optionsBtn {
  cursor: pointer;
}

.compact {
  padding: 0px;
}
