.CommentsBar {
  height: 32px;
  width: 100%;
  display: flex;
}

.titleLeft {
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 32px;
  flex: 1;
}

.titleRight {
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 32px;
  display: flex;
  flex-direction: row;
}

.btnContainer {
  margin-left: 12px;
}

.btnContainer button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.CommentsBar select {
  font: inherit;
  padding: 4px;

  cursor: pointer;
  background: transparent;
  border: none;
}
