.Home {
  background-color: #fff;
  color: #636b6f;
  font-family: "Inter", sans-serif;
  font-weight: 200;
  height: 100vh;
  margin: 0;
}

.fullHeight {
  height: 100vh;
}

.flexCenter {
  align-items: center;
  display: flex;
  justify-content: center;
}

.positionRef {
  position: relative;
}

.topRight {
  position: absolute;
  right: 10px;
  top: 18px;
}

.content {
  text-align: center;
}

.title {
  font-size: 84px;
}

.links > a,
.links > button {
  color: #636b6f;
  padding: 0 25px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.1rem;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.mbmd {
  margin-bottom: 30px;
}

.environmentDesc {
  position: absolute;
  right: 18px;
  bottom: 18px;
}

.logo {
  height: 96px;
}

.spinnerContainer {
  height: 24px;
  width: 24px;
}

.spinner {
  animation: spin 2s linear 0s infinite;
  height: 24px;
  width: 24px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
