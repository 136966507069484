.ProgressBar {
  height: 8px;
  border-radius: 4px;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  position: relative;
}

.container {
  display: flex;
  flex-direction: row;
  position: absolute;
  min-width: 8px;
}

.track {
  height: 8px;
  background: var(--primary-color-100);
  flex: 1;
}

.leftCap {
  height: 8px;
  background: var(--primary-color-100);
  width: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rightCap {
  height: 8px;
  background: var(--primary-color-100);
  width: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  left: 4px;
}
