.TopMenuLink {
  display: flex;
  border-radius: 0;
  flex-direction: column;
  color: inherit;
  margin-left: 16px;
}

.content {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  color: rgba(0, 0, 0, 0.65);
}

.indicator {
  height: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.indicatorTop {
  height: 4px;
  flex-shrink: 0;
}

.active .content {
  color: var(--primary-color-100);
}

.active .indicator {
  background: var(--primary-color-100);
}
