.TextInput,
.TextInput input {
  width: 100%;
  margin: 0;
  flex: 1;
  border-radius: 4px;
  transition: all 300ms ease;
  transition-property: box-shadow background opacity;
  border: none;
}

.inputBorder {
  padding: 0px 4px;
}

.TextInput input {
  padding: 0px 8px;
}

.TextInput input:focus {
  box-shadow: none;
  border: none;
}

.TextInput:focus-within .inputBorder {
  box-shadow: 0px 0px 0px 3px var(--primary-color-20);
  border-color: var(--primary-color-100);
  color: var(--primary-color-100);
}

.TextInput:focus-within .icon {
  border-color: var(--primary-color-100);
  color: var(--primary-color-100);
}

.TextInput.invalid .inputBorder {
  border-color: #ff0022 !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.errorContainer {
  background: rgba(255, 0, 34, 0.1);
  border: 1px solid #ff0022;
  padding: 8px 16px;
  display: block;
  max-width: 100%;
  border-radius: 4px;
  color: #ff0022;
  width: 100%;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.inputContainer {
  display: flex;
  flex-direction: row;
}

.icon {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: text;
  color: rgba(0, 0, 0, 0.65);
  z-index: 10;

  transition: all 300ms ease;
  transition-property: box-shadow background opacity;
}

.spinnerContainer {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: text;
  z-index: 10;
}

.TextInput.invalid .icon {
  color: #ff0022 !important;
}

.inputBorder {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex: 1;

  transition: all 300ms ease;
  transition-property: box-shadow background opacity;
}

.noRadiusOnBottom {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
