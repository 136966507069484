.FormWall {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 32px 24px;
}

.content {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  width: 100%;
  align-items: center;
}

.logoContainer {
  margin-bottom: 24px;
  font-size: 24px;
  text-align: center;
  font-weight: 600;

  font-family: "Inter";
}

.form {
  padding: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04);
  width: 100%;
  max-width: 328px;
  background: #ffffff;
}

@media (min-height: 600px) {
  .FormWall {
    padding: 96px 24px;
  }
}
.formActionArea {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 8px;
}

.logo {
  height: 32px;
}
